<template>
  <a-spin size="large" :spinning="loading">
    <div class="home">
      <div class="tips" v-if="$route.query.expried == '1'">
        <div class="tips-cont" :class="[lang]">
          <a-icon
            class="icon"
            type="exclamation-circle"
            :style="{ marginLeft: lang == 'AR' ? '10px' : '0px' }"
          />{{ $t("freeTrialTips") }}
        </div>
      </div>
      <div class="wrap-step">
        <a-steps :current="0">
          <a-step :title="$t('SelectSubscriptionPlan')" />
          <a-step :title="$t('BillingInformation')" />
          <a-step :title="$t('Payment')" />
        </a-steps>
      </div>
      <div class="wrap pricing" :class="[lang]">
        <div class="pricing_container">
          <div class="pricing_conflex">
            <div class="pricing_conleft">
              <div class="pricing_title">
                <h3>{{ $t("NoStrategyBrain") }}</h3>
              </div>
            </div>
            <div class="pricing_conright">
              <div class="pricing_title"><h3>StrategyBrain</h3></div>
              <div class="pricing_monthly">
                <div
                  class="pricing_monthlyopan"
                  style="direction: ltr"
                  :class="{ active: active }"
                >
                  <span @click="tabHandler()" :class="lang">{{
                    $t("Monthly")
                  }}</span
                  ><span @click="tabHandler()" :class="lang">{{
                    $t("Annually")
                  }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="pric_switchingall">
            <div
              title="Monthly"
              class="pric_switchingitem"
              :class="{ show: active }"
            >
              <div class="pric_switchinglist">
                <div
                  class="price_swlistitem price_swlistitemlie1"
                  :style="{ marginRight: lang == 'AR' ? 0 : '16px' }"
                >
                  <div class="priceitemtop">
                    <h2 class="priceitemtop_title">{{ $t("Manual") }}</h2>
                    <div class="priceitemtop_desc">
                      {{ $t("ReducecostsandImprove") }}
                    </div>
                    <div class="priceitemtop_price">
                      <b>$</b><big>5000</big
                      ><span
                        >/{{ lang == "US" ? "monthly" : $t("Monthly") }}</span
                      >
                    </div>
                  </div>
                  <div class="priceitembottom">
                    <div class="priceitem_content">
                      <p>{{ $t("40hoursofworkperweek") }}</p>
                      <p>
                        {{ $t("ClientsAndCandidatesNeed") }}
                      </p>
                      <p>
                        {{ $t("EachClientAndCandidate") }}
                      </p>
                      <p>
                        {{ $t("DelayedresponsesMakeTimely") }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="price_swlistitem price_swlistitemlie2">
                  <div class="priceitemtop">
                    <h2 class="priceitemtop_title">{{ $t("FreeTrial") }}</h2>
                    <div class="priceitemtop_desc">
                      {{ $t("ExploreourPlatform") }}
                    </div>
                    <div class="priceitemtop_price">
                      <b>$</b><big>0</big><span>/{{ $t("FirstWeek") }}</span>
                    </div>
                    <!-- <div class="priceitemtop_getbtn">
                    <a
                      href="javascript:void(0)"
                      
                      >{{$t('Purchase1')}}</a
                    >
                  </div> -->

                    <div class="priceitemtop_botselect">
                      <h3>{{ $t("Botselection") }}：</h3>

                      <div class="priceitemtop_botflex">
                        <div
                          class="priceitemtop_botflexitem"
                          :class="{ active: switchActive, disableCick }"
                          @click="switchHandler"
                        >
                          <span class="prbot_label">{{ $t("Sales") }}</span>
                          <span class="prbot_button"></span>
                        </div>
                        <div
                          class="priceitemtop_botflexitem"
                          :class="{ active: switchActive, disableCick }"
                          @click="switchHandler"
                        >
                          <span class="prbot_label">{{ $t("Recruiter") }}</span>
                          <span class="prbot_button"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="priceitembottom">
                    <div class="price_conswich">
                      <div
                        class="price_conswichitem price_conswichitemqh"
                        :class="{ show: !switchActive }"
                      >
                        <div class="priceitem_content">
                          <p>
                            <strong>{{ $t("CoreFeature") }}:</strong>
                            {{ $t("AllcorefeaturesofAISalesRep") }}
                          </p>
                          <p>
                            <strong>{{ $t("Campaign") }}:</strong> 1
                          </p>
                          <p>
                            <strong>{{ $t("SearchResults") }}:</strong> 30
                          </p>
                          <p>
                            <strong>{{ $t("ConnectionRequest") }}:</strong>
                            {{ $t("Partiallysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("Connection") }}:</strong> 27
                          </p>
                          <p>
                            <strong>{{ $t("Conversation") }}:</strong> 50
                          </p>
                          <p>
                            <strong>{{ $t("DashBoard") }}:</strong>
                            {{ $t("Notsupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("HRExpert") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                        </div>
                      </div>
                      <div
                        class="price_conswichitem price_conswichitemqh"
                        :class="{ show: switchActive }"
                      >
                        <div class="priceitem_content">
                          <p>
                            <strong>{{ $t("CoreFeature") }}:</strong>
                            {{ $t("AllcoreAIRecruiter") }}
                          </p>
                          <p>
                            <strong>{{ $t("Campaign") }}:</strong> 1
                          </p>
                          <p>
                            <strong>{{ $t("SearchResults") }}:</strong> 30
                          </p>
                          <p>
                            <strong>{{ $t("ConnectionRequest") }}:</strong>
                            {{ $t("Partiallysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("Connection") }}:</strong> 27
                          </p>
                          <p>
                            <strong>{{ $t("Conversation") }}:</strong> 50
                          </p>
                          <p>
                            <strong>{{ $t("DashBoard") }}:</strong>
                            {{ $t("Notsupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("HRExpert") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="priceitemtop_getbtnflex">
                    <div class="priceitemtop_getbtn">
                      <a
                        href="javascript:void(0)"
                        
                        >{{$t('Purchase1')}}</a
                      >
                    </div>
                  </div> -->
                  </div>
                </div>
                <div class="price_swlistitem price_swlistitemlie3">
                  <div class="priceitemtop">
                    <h2 class="priceitemtop_title">{{ $t("LitePlan") }}</h2>
                    <div
                      class="priceitemtop_desc"
                      v-html="$t('ChooseEitherAISale100')"
                    ></div>
                    <div class="priceitemtop_monthpr">
                      US${{
                        planList[0] && planList[0][1]
                          ? planList[0] && planList[0][1].price
                          : "0"
                      }}/{{ $t("month") }}
                    </div>
                    <div class="priceitemtop_price">
                      <b>≈$</b
                      ><big>{{
                        planList[0] && planList[0][1]
                          ? (planList[0] && planList[0][1].price / 30).toFixed(
                              2
                            )
                          : "0"
                      }}</big
                      ><span>/{{ $t("day") }}</span>
                    </div>
                    <div class="priceitemtop_dally">
                      {{ $t("paidMonthly") }}
                    </div>
                    <div class="priceitemtop_getbtn">
                      <a
                        href="javascript:void(0)"
                        @click="pay(planList[0][1])"
                        >{{ $t("Purchase1") }}</a
                      >
                    </div>

                    <div class="priceitemtop_botselect">
                      <h3>{{ $t("Botselection") }}：</h3>

                      <div class="priceitemtop_botflex">
                        <div
                          class="priceitemtop_botflexitem"
                          :class="{ active: switchActive, disableCick }"
                          @click="switchHandler"
                        >
                          <span class="prbot_label">{{ $t("Sales") }}</span>
                          <span class="prbot_button"></span>
                        </div>
                        <div
                          class="priceitemtop_botflexitem"
                          :class="{ active: switchActive, disableCick }"
                          @click="switchHandler"
                        >
                          <span class="prbot_label">{{ $t("Recruiter") }}</span>
                          <span class="prbot_button"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="priceitembottom">
                    <div class="price_conswich">
                      <div
                        class="price_conswichitem price_conswichitemqh"
                        :class="{ show: !switchActive }"
                      >
                        <div class="priceitem_content">
                          <p>
                            <strong>{{ $t("CoreFeature") }}:</strong> All core
                            features of&nbsp; sales rep
                          </p>
                          <p>
                            <strong>{{ $t("Campaign") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("SearchResults") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("ConnectionRequest") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("Connection") }}:</strong> 40
                          </p>
                          <p>
                            <strong>{{ $t("Conversation") }}:</strong> 100
                          </p>
                          <p>
                            <strong>{{ $t("DashBoard") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("HRExpert") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                        </div>
                      </div>
                      <div
                        class="price_conswichitem price_conswichitemqh"
                        :class="{ show: switchActive }"
                      >
                        <div class="priceitem_content">
                          <p>
                            <strong>{{ $t("CoreFeature") }}:</strong>
                            {{ $t("AllcoreAIRecruiter") }}
                          </p>
                          <p>
                            <strong>{{ $t("Campaign") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("SearchResults") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("ConnectionRequest") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("Connection") }}:</strong> 40
                          </p>
                          <p>
                            <strong>{{ $t("Conversation") }}:</strong> 100
                          </p>
                          <p>
                            <strong>{{ $t("DashBoard") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("HRExpert") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="priceitemtop_getbtnflex">
                      <div class="priceitemtop_getbtn">
                        <a
                          href="javascript:void(0)"
                          @click="pay(planList[0][1])"
                          >{{ $t("Purchase1") }}</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="price_swlistitem price_swlistitemlie4">
                  <div class="pricehot"><span>Best Seller</span></div>

                  <div class="priceitemtop">
                    <h2 class="priceitemtop_title">{{ $t("ProPlan") }}</h2>
                    <div
                      class="priceitemtop_desc"
                      v-html="$t('ChooseEitherAISale500')"
                    ></div>
                    <div class="priceitemtop_monthpr">
                      US${{
                        planList[0] && planList[0][2]
                          ? planList[0] && planList[0][2].price
                          : "0"
                      }}/{{ $t("month") }}
                    </div>
                    <div class="priceitemtop_price">
                      <b>≈$</b
                      ><big>{{
                        planList[0] && planList[0][2]
                          ? (planList[0] && planList[0][2].price / 30).toFixed(
                              2
                            )
                          : "0"
                      }}</big
                      ><span>/{{ $t("day") }}</span>
                    </div>
                    <div class="priceitemtop_dally">
                      {{ $t("paidMonthly") }}
                    </div>
                    <div class="priceitemtop_getbtn">
                      <a
                        href="javascript:void(0)"
                        @click="pay(planList[0][2])"
                        >{{ $t("Purchase1") }}</a
                      >
                    </div>

                    <div class="priceitemtop_botselect">
                      <h3>{{ $t("Botselection") }}：</h3>

                      <div class="priceitemtop_botflex">
                        <div
                          class="priceitemtop_botflexitem"
                          :class="{ active: switchActive, disableCick }"
                          @click="switchHandler"
                        >
                          <span class="prbot_label">{{ $t("Sales") }}</span>
                          <span class="prbot_button"></span>
                        </div>
                        <div
                          class="priceitemtop_botflexitem"
                          :class="{ active: switchActive, disableCick }"
                          @click="switchHandler"
                        >
                          <span class="prbot_label">{{ $t("Recruiter") }}</span>
                          <span class="prbot_button"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="priceitembottom">
                    <div class="price_conswich">
                      <div
                        class="price_conswichitem price_conswichitemqh"
                        :class="{ show: !switchActive }"
                      >
                        <div class="priceitem_content">
                          <p>
                            <strong>{{ $t("CoreFeature") }}:</strong>
                            {{ $t("AllcorefeaturesofAISalesRep") }}
                          </p>
                          <p>
                            <strong>{{ $t("Campaign") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("SearchResults") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("ConnectionRequest") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("Connection") }}:</strong> 120
                          </p>
                          <p>
                            <strong>{{ $t("Conversation") }}:</strong> 500
                          </p>
                          <p>
                            <strong>{{ $t("DashBoard") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("HRExpert") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                        </div>
                      </div>
                      <div
                        class="price_conswichitem price_conswichitemqh"
                        :class="{ show: switchActive }"
                      >
                        <div class="priceitem_content">
                          <p>
                            <strong>{{ $t("CoreFeature") }}:</strong>
                            {{ $t("AllcoreAIRecruiter") }}
                          </p>
                          <p>
                            <strong>{{ $t("Campaign") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("SearchResults") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("ConnectionRequest") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("Connection") }}:</strong> 120
                          </p>
                          <p>
                            <strong>{{ $t("Conversation") }}:</strong> 500
                          </p>
                          <p>
                            <strong>{{ $t("DashBoard") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("HRExpert") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="priceitemtop_getbtnflex">
                      <div class="priceitemtop_getbtn">
                        <a
                          href="javascript:void(0)"
                          @click="pay(planList[0][2])"
                          >{{ $t("Purchase1") }}</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="price_swlistitem price_swlistitemlie5"
                  :style="{ marginRight: lang == 'AR' ? '16px' : '0px' }"
                >
                  <div class="priceitemtop">
                    <h2 class="priceitemtop_title">{{ $t("PrimePlan") }}</h2>
                    <div
                      class="priceitemtop_desc"
                      v-html="$t('GainunlimitedAccess')"
                    ></div>
                    <div class="priceitemtop_monthpr">
                      US${{
                        planList[0] && planList[0][3]
                          ? planList[0] && planList[0][3].price
                          : "0"
                      }}/{{ $t("month") }}
                    </div>
                    <div class="priceitemtop_price">
                      <b>≈$</b
                      ><big>{{
                        planList[0] && planList[0][3]
                          ? (planList[0] && planList[0][3].price / 30).toFixed(
                              2
                            )
                          : "0"
                      }}</big
                      ><span>/{{ $t("day") }}</span>
                    </div>
                    <div class="priceitemtop_dally">
                      {{ $t("paidMonthly") }}
                    </div>
                    <div class="priceitemtop_getbtn">
                      <a
                        href="javascript:void(0)"
                        @click="pay(planList[0][3], 1)"
                        >{{ $t("Purchase1") }}</a
                      >
                    </div>

                    <div class="priceitemtop_botselect">
                      <h3>{{ $t("Botselection") }}：</h3>

                      <div class="priceitemtop_botflex">
                        <div class="priceitemtop_botflexitem noclick">
                          <span class="prbot_label">{{ $t("Sales") }}</span>
                          <span class="prbot_button"></span>
                        </div>
                        <div class="priceitemtop_botflexitem noclick">
                          <span class="prbot_label">{{ $t("Recruiter") }}</span>
                          <span class="prbot_button"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="priceitembottom">
                    <div class="price_conswich">
                      <div class="price_conswichitem show">
                        <div class="priceitem_content">
                          <p>
                            <strong
                              >{{ $t("CoreFeature") }}:
                              <span style="color: #0e756a">{{
                                $t("Allfeatures")
                              }}</span></strong
                            >
                          </p>
                          <p>
                            <strong>{{ $t("Campaign") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("SearchResults") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("ConnectionRequest") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("Connection") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("Conversation") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("DashBoard") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("HRExpert") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                        </div>
                      </div>
                      <div class="price_conswichitem">
                        <div class="priceitem_content">
                          <p>
                            <strong>{{ $t("CoreFeature") }}:</strong>
                            {{ $t("Allfeatures") }}
                          </p>
                          <p>
                            <strong>{{ $t("Campaign") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("SearchResults") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("ConnectionRequest") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("Connection") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("Conversation") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("DashBoard") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("HRExpert") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="priceitemtop_getbtnflex">
                      <div class="priceitemtop_getbtn">
                        <a
                          href="javascript:void(0)"
                          @click="pay(planList[0][3], 1)"
                          >{{ $t("Purchase1") }}</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              title="Annually"
              class="pric_switchingitem"
              :class="{ show: !active }"
            >
              <div class="pric_switchinglist">
                <div
                  class="price_swlistitem price_swlistitemlie1"
                  :style="{ marginRight: lang == 'AR' ? 0 : '16px' }"
                >
                  <div class="priceitemtop">
                    <h2 class="priceitemtop_title">{{ $t("Manual") }}</h2>
                    <div class="priceitemtop_desc">
                      {{ $t("ReducecostsandImprove") }}
                    </div>
                    <div class="priceitemtop_price">
                      <b>$</b><big>5000</big
                      ><span
                        >/{{ lang == "US" ? "monthly" : $t("Monthly") }}</span
                      >
                    </div>
                  </div>
                  <div class="priceitembottom">
                    <div class="priceitem_content">
                      <p>{{ $t("40hoursofworkperweek") }}</p>
                      <p>
                        {{ $t("ClientsAndCandidatesNeed") }}
                      </p>
                      <p>
                        {{ $t("EachClientAndCandidate") }}
                      </p>
                      <p>
                        {{ $t("DelayedresponsesMakeTimely") }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="price_swlistitem price_swlistitemlie2">
                  <div class="priceitemtop">
                    <h2 class="priceitemtop_title">{{ $t("FreeTrial") }}</h2>
                    <div class="priceitemtop_desc">
                      {{ $t("ExploreourPlatform") }}
                    </div>
                    <div class="priceitemtop_price">
                      <b>$</b><big>0</big><span>/{{ $t("FirstWeek") }}</span>
                    </div>
                    <!-- <div class="priceitemtop_getbtn">
                    <a href="javascript:void(0)" >{{$t('Purchase1')}}</a>
                  </div> -->

                    <div class="priceitemtop_botselect">
                      <h3>{{ $t("Botselection") }}：</h3>

                      <div class="priceitemtop_botflex">
                        <div
                          class="priceitemtop_botflexitem"
                          :class="{ active: switchActive, disableCick }"
                          @click="switchHandler"
                        >
                          <span class="prbot_label">{{ $t("Sales") }}</span>
                          <span class="prbot_button"></span>
                        </div>
                        <div
                          class="priceitemtop_botflexitem"
                          :class="{ active: switchActive, disableCick }"
                          @click="switchHandler"
                        >
                          <span class="prbot_label">{{ $t("Recruiter") }}</span>
                          <span class="prbot_button"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="priceitembottom">
                    <div class="price_conswich">
                      <div
                        class="price_conswichitem price_conswichitemqh"
                        :class="{ show: !switchActive }"
                      >
                        <div class="priceitem_content">
                          <p>
                            <strong>{{ $t("CoreFeature") }}:</strong>
                            {{ $t("AllcorefeaturesofAISalesRep") }}
                          </p>
                          <p>
                            <strong>{{ $t("Campaign") }}:</strong> 1
                          </p>
                          <p>
                            <strong>{{ $t("SearchResults") }}:</strong> 30
                          </p>
                          <p>
                            <strong>{{ $t("ConnectionRequest") }}:</strong>
                            {{ $t("Partiallysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("Connection") }}:</strong> 27
                          </p>
                          <p>
                            <strong>{{ $t("Conversation") }}:</strong> 50
                          </p>
                          <p>
                            <strong>{{ $t("DashBoard") }}:</strong>
                            {{ $t("Notsupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("HRExpert") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                        </div>
                      </div>
                      <div
                        class="price_conswichitem price_conswichitemqh"
                        :class="{ show: switchActive }"
                      >
                        <div class="priceitem_content">
                          <p>
                            <strong>{{ $t("CoreFeature") }}:</strong>
                            {{ $t("AllcoreAIRecruiter") }}
                          </p>
                          <p>
                            <strong>{{ $t("Campaign") }}:</strong> 1
                          </p>
                          <p>
                            <strong>{{ $t("SearchResults") }}:</strong> 30
                          </p>
                          <p>
                            <strong>{{ $t("ConnectionRequest") }}:</strong>
                            {{ $t("Partiallysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("Connection") }}:</strong> 27
                          </p>
                          <p>
                            <strong>{{ $t("Conversation") }}:</strong> 50
                          </p>
                          <p>
                            <strong>{{ $t("DashBoard") }}:</strong>
                            {{ $t("Notsupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("HRExpert") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="priceitemtop_getbtnflex">
                    <div class="priceitemtop_getbtn">
                      <a href="javascript:void(0)" 
                        >{{$t('Purchase1')}}</a
                      >
                    </div>
                  </div> -->
                  </div>
                </div>
                <div class="price_swlistitem price_swlistitemlie3">
                  <div class="priceitemtop">
                    <h2 class="priceitemtop_title">{{ $t("LitePlan") }}</h2>
                    <div
                      class="priceitemtop_desc"
                      v-html="$t('litePlanDes')"
                    ></div>
                    <div class="priceitemtop_monthpr">
                      <span
                        >US$
                        {{
                          planList[1] && planList[1][1]
                            ? planList[1][1].original_price
                            : 0
                        }}/{{ $t("year") }}</span
                      ><span
                        >$
                        {{
                          planList[1] && planList[1][1]
                            ? planList[1][1].price
                            : 0
                        }}/{{ $t("year") }}</span
                      >
                    </div>
                    <div class="priceitemtop_price">
                      <b>≈$</b
                      ><big>{{
                        planList[1] && planList[1][1]
                          ? (planList[1][1].price / 365).toFixed(2)
                          : 0
                      }}</big
                      ><span>/{{ $t("day") }}</span>
                    </div>
                    <div class="priceitemtop_dally">
                      {{ $t("paidAnnually") }}
                    </div>
                    <div class="priceitemtop_getbtn">
                      <a
                        href="javascript:void(0)"
                        @click="pay(planList[1][1])"
                        >{{ $t("Purchase1") }}</a
                      >
                    </div>

                    <div class="priceitemtop_botselect">
                      <h3>{{ $t("Botselection") }}：</h3>

                      <div class="priceitemtop_botflex">
                        <div
                          class="priceitemtop_botflexitem"
                          :class="{ active: switchActive, disableCick }"
                          @click="switchHandler"
                        >
                          <span class="prbot_label">{{ $t("Sales") }}</span>
                          <span class="prbot_button"></span>
                        </div>
                        <div
                          class="priceitemtop_botflexitem"
                          :class="{ active: switchActive, disableCick }"
                          @click="switchHandler"
                        >
                          <span class="prbot_label">{{ $t("Recruiter") }}</span>
                          <span class="prbot_button"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="priceitembottom">
                    <div class="price_conswich">
                      <div
                        class="price_conswichitem price_conswichitemqh"
                        :class="{ show: !switchActive }"
                      >
                        <div class="priceitem_content">
                          <p>
                            <strong>{{ $t("CoreFeature") }}:</strong>
                            {{ $t("AllcorefeaturesofAISalesRep") }}
                          </p>
                          <p>
                            <strong>{{ $t("Campaign") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("SearchResults") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("ConnectionRequest") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("Connection") }}:</strong> 40
                          </p>
                          <p>
                            <strong>{{ $t("Conversation") }}:</strong> 100
                          </p>
                          <p>
                            <strong>{{ $t("DashBoard") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("HRExpert") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                        </div>
                      </div>
                      <div
                        class="price_conswichitem price_conswichitemqh"
                        :class="{ show: switchActive }"
                      >
                        <div class="priceitem_content">
                          <p>
                            <strong>{{ $t("CoreFeature") }}:</strong>
                            {{ $t("AllcoreAIRecruiter") }}
                          </p>
                          <p>
                            <strong>{{ $t("Campaign") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("SearchResults") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("ConnectionRequest") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("Connection") }}:</strong> 40
                          </p>
                          <p>
                            <strong>{{ $t("Conversation") }}:</strong> 100
                          </p>
                          <p>
                            <strong>{{ $t("DashBoard") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("HRExpert") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="priceitemtop_getbtnflex">
                      <div class="priceitemtop_getbtn">
                        <a
                          href="javascript:void(0)"
                          @click="pay(planList[1][1])"
                          >{{ $t("Purchase1") }}</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="price_swlistitem price_swlistitemlie4">
                  <div class="pricehot"><span>Best Seller</span></div>

                  <div class="priceitemtop">
                    <h2 class="priceitemtop_title">{{ $t("ProPlan") }}</h2>
                    <div
                      class="priceitemtop_desc"
                      v-html="$t('proPlanDes')"
                    ></div>
                    <div class="priceitemtop_monthpr">
                      <span
                        >US$
                        {{
                          planList[1] && planList[1][2]
                            ? planList[1][2].original_price
                            : 0
                        }}/{{ $t("year") }}</span
                      ><span
                        >$
                        {{
                          planList[1] && planList[1][2]
                            ? planList[1][2].price
                            : 0
                        }}/{{ $t("year") }}</span
                      >
                    </div>
                    <div class="priceitemtop_price">
                      <b>≈$</b
                      ><big>{{
                        planList[1] && planList[1][2]
                          ? (planList[1][2].price / 365).toFixed(2)
                          : 0
                      }}</big
                      ><span>/{{ $t("day") }}</span>
                    </div>
                    <div class="priceitemtop_dally">
                      {{ $t("paidAnnually") }}
                    </div>
                    <div class="priceitemtop_getbtn">
                      <a
                        href="javascript:void(0)"
                        @click="pay(planList[1][2])"
                        >{{ $t("Purchase1") }}</a
                      >
                    </div>

                    <div class="priceitemtop_botselect">
                      <h3>{{ $t("Botselection") }}：</h3>

                      <div class="priceitemtop_botflex">
                        <div
                          class="priceitemtop_botflexitem"
                          :class="{ active: switchActive, disableCick }"
                          @click="switchHandler"
                        >
                          <span class="prbot_label">{{ $t("Sales") }}</span>
                          <span class="prbot_button"></span>
                        </div>
                        <div
                          class="priceitemtop_botflexitem"
                          :class="{ active: switchActive, disableCick }"
                          @click="switchHandler"
                        >
                          <span class="prbot_label">{{ $t("Recruiter") }}</span>
                          <span class="prbot_button"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="priceitembottom">
                    <div class="price_conswich">
                      <div
                        class="price_conswichitem price_conswichitemqh"
                        :class="{ show: !switchActive }"
                      >
                        <div class="priceitem_content">
                          <p>
                            <strong>{{ $t("CoreFeature") }}:</strong>
                            {{ $t("AllcorefeaturesofAISalesRep") }}
                          </p>
                          <p>
                            <strong>{{ $t("Campaign") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("SearchResults") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("ConnectionRequest") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("Connection") }}:</strong> 120
                          </p>
                          <p>
                            <strong>{{ $t("Conversation") }}:</strong> 500
                          </p>
                          <p>
                            <strong>{{ $t("DashBoard") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("HRExpert") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                        </div>
                      </div>
                      <div
                        class="price_conswichitem price_conswichitemqh"
                        :class="{ show: switchActive }"
                      >
                        <div class="priceitem_content">
                          <p>
                            <strong>{{ $t("CoreFeature") }}:</strong>
                            {{ $t("AllcoreAIRecruiter") }}
                          </p>
                          <p>
                            <strong>{{ $t("Campaign") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("SearchResults") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("ConnectionRequest") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("Connection") }}:</strong> 120
                          </p>
                          <p>
                            <strong>{{ $t("Conversation") }}:</strong> 500
                          </p>
                          <p>
                            <strong>{{ $t("DashBoard") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("HRExpert") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="priceitemtop_getbtnflex">
                      <div class="priceitemtop_getbtn">
                        <a
                          href="javascript:void(0)"
                          @click="pay(planList[1][2])"
                          >{{ $t("Purchase1") }}</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="price_swlistitem price_swlistitemlie5"
                  :style="{ marginRight: lang == 'AR' ? '16px' : '0px' }"
                >
                  <div class="priceitemtop">
                    <h2 class="priceitemtop_title">{{ $t("PrimePlan") }}</h2>
                    <div
                      class="priceitemtop_desc"
                      v-html="$t('primePlanDes')"
                    ></div>
                    <div class="priceitemtop_monthpr">
                      <span
                        >US$
                        {{
                          planList[1] && planList[1][3]
                            ? planList[1][1].original_price
                            : 0
                        }}/{{ $t("year") }}</span
                      ><span
                        >$
                        {{
                          planList[1] && planList[1][3]
                            ? planList[1][3].price
                            : 0
                        }}/{{ $t("year") }}</span
                      >
                    </div>
                    <div class="priceitemtop_price">
                      <b>≈$</b
                      ><big>{{
                        planList[1] && planList[1][3]
                          ? (planList[1][3].price / 365).toFixed(2)
                          : 0
                      }}</big
                      ><span>/{{ $t("day") }}</span>
                    </div>
                    <div class="priceitemtop_dally">
                      {{ $t("paidAnnually") }}
                    </div>
                    <div class="priceitemtop_getbtn">
                      <a
                        href="javascript:void(0)"
                        @click="pay(planList[1][3], 1)"
                        >{{ $t("Purchase1") }}</a
                      >
                    </div>

                    <div class="priceitemtop_botselect">
                      <h3>{{ $t("Botselection") }}：</h3>

                      <div class="priceitemtop_botflex">
                        <div class="priceitemtop_botflexitem noclick">
                          <span class="prbot_label">{{ $t("Sales") }}</span>
                          <span class="prbot_button"></span>
                        </div>
                        <div class="priceitemtop_botflexitem noclick">
                          <span class="prbot_label">{{ $t("Recruiter") }}</span>
                          <span class="prbot_button"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="priceitembottom">
                    <div class="price_conswich">
                      <div class="price_conswichitem show">
                        <div class="priceitem_content">
                          <p>
                            <strong
                              >{{ $t("CoreFeature") }}:
                              <span style="color: #0e756a">{{
                                $t("Allfeatures")
                              }}</span></strong
                            >
                          </p>
                          <p>
                            <strong>{{ $t("Campaign") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("SearchResults") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("ConnectionRequest") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("Connection") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("Conversation") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("DashBoard") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("HRExpert") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                        </div>
                      </div>
                      <div class="price_conswichitem">
                        <div class="priceitem_content">
                          <p>
                            <strong>{{ $t("CoreFeature") }}:</strong>
                            {{ $t("Allfeatures") }}
                          </p>
                          <p>
                            <strong>{{ $t("Campaign") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("SearchResults") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("ConnectionRequest") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("Connection") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("Conversation") }}:</strong>
                            {{ $t("Unlimited") }}
                          </p>
                          <p>
                            <strong>{{ $t("DashBoard") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                          <p>
                            <strong>{{ $t("HRExpert") }}:</strong>
                            {{ $t("Fullysupported") }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="priceitemtop_getbtnflex">
                      <div class="priceitemtop_getbtn">
                        <a
                          href="javascript:void(0)"
                          @click="pay(planList[1][3], 1)"
                          >{{ $t("Purchase1") }}</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>
<script>
export default {
  data() {
    return {
      step: 1,
      active: false,
      current: 0,
      switchActive: false,
      planList: [],
      loading: true,
      disableCick: false,
    };
  },
  i18n: require("../i18n"),
  created() {
    let { campaign_type } = this.$route.query;
    this.switchActive = campaign_type == 1 || !campaign_type ? false : true;
    if (campaign_type == 1 || campaign_type == 2) {
      this.disableCick = true;
    }

    Promise.all([this.getPlanList(2), this.getPlanList(3)])
      .then((res) => {
        this.planList = res;
        this.loading = false;
      })
      .catch((e) => {
        console.log(e);
        this.loading = false;
      });
  },
  methods: {
    tabHandler() {
      this.active = !this.active;
    },
    goplan2() {
      this.$route.push({
        path: "/userSetting/planStep2",
        query: this.$route.query,
      });
    },
    switchHandler() {
      if (this.disableCick) {
        return;
      }
      this.switchActive = !this.switchActive;
    },
    pay(obj, all) {
      this.$router.push({
        path: "/setting/userSetting/planStep2",
        query: {
          ...obj,
          ...this.$route.query,
          duration_type: this.active ? 2 : 3,
          campaign_type: all ? 3 : this.switchActive ? 2 : 1,
        },
      });
    },
    getPlanList(type) {
      return new Promise((resolve, reject) => {
        this.$axios(
          "/payment/plan_list",
          {
            duration_type: type,
          },
          "post"
        )
          .then((res) => {
            if (res.code == 200) {
              resolve(res.data);
            } else {
              reject(res.msg);
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
};
</script>
<style lang="less" scoped>
@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("fonts/Poppins-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-ExtraBold";
  src: url("fonts/Poppins-ExtraBold.ttf") format("truetype");
}

.home {
  margin-top: 50px;
  padding: 0px 30px 20px 30px;
  .tips {
    padding-top: 50px;
    padding-bottom: 12px;
    .tips-cont {
      height: 64px;
      line-height: 64px;
      background: #ffdfdf;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #ff0c0c;
      text-align: center;
      color: #ff0c0c;
      font-weight: bold;
      font-size: 16px;
      .icon {
        margin-right: 8px;
      }
    }
  }
  .wrap-step {
    padding-top: 53px;
    width: 800px;
    margin: 0 auto;
  }
  // .pricingbg{ padding:88px 0; position:relative; background:url(images/pricingbg.png) center center; background-size:cover;}
  .pricingbg .finddustomers_ellipse {
    top: 35%;
  }
  .pricingbg .finddustomers_ellipsebottom {
    bottom: 15%;
  }
  .pricing {
    position: relative;
    z-index: 2;
  }
  .pricing_container {
    box-shadow: 0px 4px 24px 0px #2d2d2d21;
    background: #ffffff;
    padding: 32px 16px 24px 16px;
    margin-top: 56px;
    border-radius: 16px;
  }
  .pricing_conflex {
    display: flex;
    justify-content: space-between;
  }
  .pricing_conleft {
    width: 220px;
  }
  .pricing_conright {
    width: calc(100% - 244px);
  }

  .pricing_title {
    background: #d9d9d9;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
  }
  .pricing_title h3 {
    font-size: 22px;
    font-family: "Poppins-Bold";
    line-height: 36px;
    white-space: nowrap;
  }
  .pricing_monthly {
    line-height: 56px;
    margin-top: 24px;
    color: #252525;
    font-size: 16px;
    text-align: center;
  }
  .pricing_pricecon {
    margin-top: 32px;
  }
  .pricing_pricecon h2 {
    font-size: 32px;
    font-weight: 900;
    line-height: 48px;
    color: #252525;
    font-family: "Poppins-ExtraBold";
  }
  .pricing_pricecon p {
    color: rgba(37, 37, 37, 0.6);
    font-size: 16px;
    font-weight: 400;
    margin-top: 8px;
    line-height: 24px;
    min-height: 48px;
  }
  .pricing_price {
    margin-top: 40px;
  }
  .pricing_price b {
    font-size: 24px;
    color: #252525;
    font-weight: 600;
    line-height: 36px;
  }
  .pricing_price big {
    color: #2d2d2d;
    font-size: 64px;
    font-weight: 900;
    line-height: 96px;
    font-family: "Poppins-ExtraBold";
  }
  .pricing_price span {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #252525;
  }

  .pricing_sales {
    margin-top: 42px;
  }
  .pricing_salesitem {
    background: #f9f9f9;
    border: #c5cfde 1px solid;
    padding: 24px 16px;
    border-radius: 8px;
    margin-bottom: 16px;
  }
  .pricing_salesitemtit {
    color: #252525;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  .pricing_salesitemlist {
    margin-top: 16px;
  }
  .pricing_salesitemlist li {
    color: rgba(37, 37, 37, 0.6);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 8px;
    padding-left: 14px;
    position: relative;
  }
  .pricing_salesitemlist li:before {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    background: #2d2d2d;
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 8px;
  }
  .pricing_salesitemlist li:last-child {
    margin-bottom: 0;
  }

  .pricing_conright .pricing_title {
    background: #0e756a;
  }
  .pricing_conright .pricing_title h3 {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pricing_conright .pricing_title h3:before {
    content: "";
    display: block;
    width: 36px;
    height: 36px;
    background: url(../../../assets/img/Subtract.svg) no-repeat;
    background-size: cover;
    margin-right: 8px;
  }

  .pricing_monthlyopan {
    width: 355px;
    display: flex;
    background: #f9f9f9;
    justify-content: space-between;
    border: #c5cfde 1px solid;
    border-radius: 16px;
    margin: 0 auto;
    cursor: pointer;
    position: relative;
  }
  .pricing_monthlyopan span {
    color: #252525;
    width: 40%;
    display: block;
    font-size: 16px;
    height: 56px;
    font-weight: 600;
    line-height: 56px;
    text-align: center;
    border-radius: 16px;
    position: relative;
    z-index: 2;
    transition: all 0.3s;
  }
  .pricing_monthlyopan span:nth-child(2) {
    color: #fff;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pricing_monthlyopan span:nth-child(2):before,
  .pricehot span:before {
    margin-right: 3px;
    content: "";
    display: block;
    width: 14px;
    height: 17px;
    background: url(../../../assets/img/hot.png) no-repeat;
  }
  .pricing_monthlyopan:after {
    content: "";
    display: block;
    background: #0e756a;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0%;
    width: 60%;
    z-index: 1;
    border-radius: 16px;
    transition: all 0.3s;
  }
  .pricing_monthlyopan.active:after {
    right: 60%;
    width: 40%;
  }
  .pricing_monthlyopan.active span:nth-child(1) {
    color: #fff5dc;
  }
  .pricing_monthlyopan.active span:nth-child(2) {
    color: #252525;
  }

  .priveold {
    font-size: 16px;
    line-height: 24px;
  }
  .priveold b {
    color: #0e756a;
    text-decoration: line-through;
    margin-right: 10px;
  }
  .priveold span:nth-child(3) {
    display: none;
  }
  .priveold.hide b {
    display: none;
  }
  .priveold.hide span:nth-child(2) {
    display: none;
  }
  .priveold.hide span:nth-child(3) {
    display: inline-block;
  }

  .pricedaily {
    color: #252525;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    background: #f8fcf8;
    padding: 8px 30px;
    display: inline-block;
    border-radius: 16px;
    margin-top: -3px;
  }

  .pricing_conrflex {
    display: flex;
    justify-content: space-between;
  }
  .pricing_conrflexitem {
    width: 47.8%;
  }
  .pricing_conrflexitem .pricing_price big {
    color: #252525;
  }

  .linkedin_link {
  }
  .linkedin_link a,
  .linkedin_link select {
    padding: 5px 14px;
    display: block;
    font-size: 16px;
    background: #f9f9f9;
    line-height: 1.5;
    text-align: left;
    color: #252525;
    border-radius: 4px;
  }
  .linkedin_link a span {
    color: #ff3c3c;
  }

  .linkedin_btn {
    text-align: center;
    margin-top: 20px;
  }
  .linkedin_btn a {
    display: inline-block;
    background: #f9f9f9;
    color: #252525;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    padding: 8px 25px;
    border-radius: 16px;
  }

  .changepricetext span:nth-child(2) {
    display: none;
  }
  .changepricetext.active span:nth-child(1) {
    display: none;
  }
  .changepricetext.active span:nth-child(2) {
    display: inline-block;
  }

  .linkedin_link select {
    border: #c5cfde 1px solid;
    background: url(../../../assets/img/account_icon.png) no-repeat 97% center
      #f8fcf8;
    cursor: pointer;
    width: 100%;
    padding: 4px 16px;
  }
  .pricing_conrflexitem:nth-child(2) .pricing_price {
    margin-top: 13px;
  }
  .pricing_conrflexitem:nth-child(2) .pricing_sales {
    margin-top: 16px;
  }

  .changeprice big {
    transition: all 0.3s;
  }
  .changeprice big:nth-child(3),
  .changeprice.active big:nth-child(2) {
    display: none;
  }
  .changeprice.active big:nth-child(3) {
    display: inline-block;
  }

  .pricing_saleslist {
    margin-top: 32px;
  }

  /*20240809*/
  .pricing_conrflexitem:nth-child(1) .pricing_sales {
    margin-top: 0;
  }
  .pricing_conrflexitem:nth-child(2) {
    margin-top: 16px;
    padding: 16px;
    border-radius: 16px;
    background: #f8fcf8;
    box-shadow: 0px 0px 24px 0px #0e756a29;
  }
  .pricing_conrflexitem:nth-child(2) .pricing_pricecon {
    margin-top: 0;
  }
  .pricing_conrflexitem:nth-child(2) .pricing_price big {
    color: #0e756a;
  }
  .pricing_conrflexitem:nth-child(2) .linkedin_btn a {
    background: #0e756a;
    color: #fff;
  }
  .pricing_conrflexitem:nth-child(2) .pricing_salesitem {
    background: #fff;
  }

  /*Price åº•*/
  .pricedatabg {
    padding: 56px 0;
    background: #f8fcf8;
  }
  .pricedata {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .pricedataflex {
    display: flex;
    align-items: center;
  }
  .pricedatatit {
    color: #252525;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
  }
  .pricedatalist {
  }
  .pricedatalist ul {
    display: flex;
  }
  .pricedatalist li {
    border-left: #0e756a 1px solid;
    padding-left: 32px;
    margin-left: 32px;
  }
  .pricedatalist span {
    color: #252525;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    display: block;
  }
  .pricedatalist span.num {
  }
  .pricedatalist span.unit {
    margin-top: 16px;
  }
  .pricedatarcon .linkedin_btn a {
    background: none;
  }
  .pricedatarcon h3 {
    color: #252525;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
  }

  .pric_switchingall {
    margin-top: 24px;
  }
  .pric_switchingall .pric_switchingitem {
    visibility: hidden;
    opacity: 0;
    height: 0;
    overflow: hidden;
    display: none;
  }
  .pric_switchingall .pric_switchingitem.show {
    opacity: 1;
    height: auto;
    visibility: visible;
    overflow: visible;
    display: block;
  }
  .pric_switchinglist {
    display: flex;
  }
  .pric_switchinglist .price_swlistitem {
    width: 220px;
    margin-right: 16px;
    border: #c5cfde 1px solid;
    border-radius: 8px;
    padding: 28px 8px 104px;
    position: relative;
    flex: 1;
  }
  .pric_switchinglist .price_swlistitem:last-child {
    margin-right: 0;
  }
  .pric_switchinglist .price_swlistitemlie1 {
    background: #f9f9f9;
  }
  .pric_switchinglist .price_swlistitemlie2 {
    background: #ffffff;
  }
  .pric_switchinglist .price_swlistitemlie3 {
    background: #f8fcf8;
  }
  .pric_switchinglist .price_swlistitemlie4 {
    background: #f8fcf8;
    border-color: #0e756a;
    box-shadow: 0px 4px 16px 0px #0000003d;
  }
  .pric_switchinglist .price_swlistitemlie5 {
    background: #f8fcf8;
  }

  .pricehot {
    background: #ff7426;
    border: #ed3e00 1px solid;
    width: 133px;
    height: 32px;
    text-align: center;
    border-radius: 16px;
    position: absolute;
    right: -8px;
    top: -16px;
    z-index: 2;
  }
  .pricehot span {
    color: #fff;
    display: flex;
    height: 100%;
    font-weight: 600;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    justify-content: center;
  }

  .price_swlistitem .priceitemtop {
    border-bottom: 1px dashed #c5cfde;
    padding-bottom: 16px;
    min-height: 430px;
  }
  .price_swlistitem .priceitemtop .priceitemtop_title {
    font-size: 24px;
    font-weight: 900;
    line-height: 36px;
    color: #252525;
    font-family: "Poppins-ExtraBold";
  }
  .price_swlistitem .priceitemtop .priceitemtop_desc {
    margin-top: 16px;
    font-size: 14px;
    line-height: 21px;
    color: rgba(37, 37, 37, 0.6);
    min-height: 105px;
  }
  .price_swlistitem .priceitemtop .priceitemtop_desc span {
    color: #6caba3;
  }
  .price_swlistitem .priceitemtop .priceitemtop_monthpr {
    font-size: 14px;
    line-height: 21px;
    color: #656b61;
    margin-top: 3px;
  }
  .price_swlistitem .priceitemtop .priceitemtop_monthpr span:nth-child(1) {
    text-decoration: line-through;
  }
  .price_swlistitem .priceitemtop .priceitemtop_monthpr span:nth-child(2) {
    color: #0e756a;
    margin-left: 8px;
  }
  .price_swlistitem .priceitemtop .priceitemtop_dally {
    font-size: 14px;
    line-height: 21px;
    color: #252525;
  }

  .price_swlistitem.price_swlistitemlie1 .priceitemtop .priceitemtop_price,
  .price_swlistitem.price_swlistitemlie2 .priceitemtop .priceitemtop_price {
    margin-top: 24px;
  }

  .priceitemtop_price b {
    font-size: 14px;
    color: #252525;
    font-weight: 600;
    line-height: 21px;
  }
  .priceitemtop_price big {
    color: #2d2d2d;
    font-size: 32px;
    font-weight: 900;
    line-height: 48px;
    font-family: "Poppins-ExtraBold";
  }
  .priceitemtop_price span {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: #252525;
  }

  .price_swlistitem .priceitemtop_getbtn {
    text-align: center;
    margin-top: 37px;
  }
  .price_swlistitem .priceitemtop_getbtn a {
    display: inline-block;
    background: #0e756a;
    border: #0e756a 1px solid;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    padding: 8px 24px;
    border-radius: 16px;
    white-space: nowrap;
  }

  .price_swlistitem.price_swlistitemlie2 .priceitemtop_getbtn {
    margin-top: 60px;
  }
  .price_swlistitem.price_swlistitemlie2 .priceitemtop_getbtn a {
    background: #fff;
    border: #000 1px solid;
    color: #252525;
  }

  .price_swlistitem .priceitembottom .priceitemtop_getbtnflex {
    position: absolute;
    width: 100%;
    bottom: 32px;
    left: 0%;
    display: flex;
    justify-content: center;
  }
  .price_swlistitem .priceitembottom .priceitemtop_getbtn {
    margin: 0;
  }

  .priceitemtop_botselect {
    margin-top: 24px;
  }
  .priceitemtop_botselect h3 {
    font-size: 14px;
    line-height: 21px;
    color: #252525;
    font-weight: 600;
  }
  .priceitemtop_botflex {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
  }
  .priceitemtop_botflex .priceitemtop_botflexitem {
    display: flex;
    align-items: center;
  }
  .priceitemtop_botflex span {
    cursor: pointer;
  }
  .priceitemtop_botflex .prbot_label {
    font-size: 14px;
    line-height: 21px;
    color: #252525;
    font-weight: 600;
    margin-right: 4px;
  }
  .priceitemtop_botflex .prbot_button {
    display: block;
    width: 32px;
    height: 18px;
    background: #0e756a;
    border-radius: 33px;
    position: relative;
  }
  .priceitemtop_botflex .prbot_button:after {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    background: #fff;
    position: absolute;
    right: 2px;
    top: 2px;
    transition: all 0.3s;
  }
  .priceitemtop_botflex .priceitemtop_botflexitem:nth-child(2) .prbot_button {
    background: #cecece;
  }
  .priceitemtop_botflex
    .priceitemtop_botflexitem:nth-child(2)
    .prbot_button:after {
    right: calc(100% - 16px);
  }

  .priceitemtop_botflex
    .priceitemtop_botflexitem.active:nth-child(1)
    .prbot_button {
    background: #cecece;
  }
  .priceitemtop_botflex
    .priceitemtop_botflexitem.active:nth-child(1)
    .prbot_button:after {
    right: calc(100% - 16px);
  }
  .priceitemtop_botflex
    .priceitemtop_botflexitem.active:nth-child(2)
    .prbot_button {
    background: #0e756a;
  }
  .priceitemtop_botflex
    .priceitemtop_botflexitem.active:nth-child(2)
    .prbot_button:after {
    right: 2px;
  }

  .priceitemtop_botflex .noclick .prbot_button,
  .priceitemtop_botflex
    .priceitemtop_botflexitem.noclick:nth-child(2)
    .prbot_button,
  .priceitemtop_botflex
    .priceitemtop_botflexitem.active.noclick:nth-child(1)
    .prbot_button {
    background: #b2d4ce;
  }
  .priceitemtop_botflex
    .priceitemtop_botflexitem.noclick:nth-child(2)
    .prbot_button:after,
  .priceitemtop_botflex
    .priceitemtop_botflexitem.active.noclick:nth-child(1)
    .prbot_button:after {
    right: 2px;
  }

  .price_swlistitem .priceitembottom {
    padding-top: 16px;
  }
  .price_conswich {
  }
  .price_conswich .price_conswichitem {
    visibility: hidden;
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: all 0.5s;
  }
  .price_conswich .price_conswichitem.show {
    opacity: 1;
    height: auto;
    visibility: visible;
  }
  .price_swlistitem .priceitembottom .priceitem_content p {
    font-size: 14px;
    line-height: 21px;
    color: #7a7a7a;
    margin-bottom: 16px;
  }
  .price_swlistitem.price_swlistitemlie1 .priceitembottom .priceitem_content p {
    color: #252525;
  }
  .price_swlistitem .priceitembottom .priceitem_content p:last-child {
    margin-bottom: 0;
  }
  .price_swlistitem .priceitembottom .priceitem_content p strong {
    color: #252525;
  }
  .price_swlistitem .priceitembottom .priceitem_content p span {
    color: #0e756a;
    font-weight: 600;
  }

  @media (max-width: 1280px) {
    .pric_switchinglist .price_swlistitem {
      width: 18.4%;
      margin-right: 2%;
    }
  }
  @media (max-width: 1160px) {
    .pric_switchinglist {
      flex-wrap: wrap;
    }
    .pric_switchinglist .price_swlistitem {
      width: 32%;
      margin-bottom: 30px;
    }
    .pric_switchinglist .price_swlistitem:nth-child(3n) {
      margin-right: 0;
    }
  }
  @media (max-width: 768px) {
    .pricing_conright {
      margin-top: 20px;
    }
    .pric_switchinglist {
      flex-wrap: wrap;
    }
    .pric_switchinglist .price_swlistitem {
      margin-bottom: 20px;
      width: 100%;
      margin-right: 0;
      padding: 20px 8px;
    }
    .price_swlistitem .priceitemtop .priceitemtop_desc,
    .price_swlistitem .priceitemtop {
      min-height: inherit;
    }
    .price_swlistitem.price_swlistitemlie1 .priceitemtop .priceitemtop_price,
    .price_swlistitem.price_swlistitemlie2 .priceitemtop .priceitemtop_price {
      margin-top: 0;
    }
    .price_swlistitem .priceitembottom .priceitemtop_getbtnflex {
      position: static;
      margin-top: 20px;
    }
    .price_swlistitem .priceitemtop_getbtn,
    .price_swlistitem.price_swlistitemlie2 .priceitemtop_getbtn {
      margin-top: 20px;
    }
  }

  h3 {
    margin-bottom: 0;
  }
  /deep/.ant-steps-item-title::after {
    background-color: #e1e1e1;
    height: 2px;
  }
  /deep/.ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #e1e1e1;
  }
  /deep/.ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #e1e1e1;
  }
}
.disableCick {
  opacity: 0.5;
}
</style>
