var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-spin", { attrs: { size: "large", spinning: _vm.loading } }, [
    _c("div", { staticClass: "home" }, [
      _vm.$route.query.expried == "1"
        ? _c("div", { staticClass: "tips" }, [
            _c(
              "div",
              { staticClass: "tips-cont", class: [_vm.lang] },
              [
                _c("a-icon", {
                  staticClass: "icon",
                  style: { marginLeft: _vm.lang == "AR" ? "10px" : "0px" },
                  attrs: { type: "exclamation-circle" }
                }),
                _vm._v(_vm._s(_vm.$t("freeTrialTips")) + " ")
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "wrap-step" },
        [
          _c(
            "a-steps",
            { attrs: { current: 0 } },
            [
              _c("a-step", {
                attrs: { title: _vm.$t("SelectSubscriptionPlan") }
              }),
              _c("a-step", { attrs: { title: _vm.$t("BillingInformation") } }),
              _c("a-step", { attrs: { title: _vm.$t("Payment") } })
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "wrap pricing", class: [_vm.lang] }, [
        _c("div", { staticClass: "pricing_container" }, [
          _c("div", { staticClass: "pricing_conflex" }, [
            _c("div", { staticClass: "pricing_conleft" }, [
              _c("div", { staticClass: "pricing_title" }, [
                _c("h3", [_vm._v(_vm._s(_vm.$t("NoStrategyBrain")))])
              ])
            ]),
            _c("div", { staticClass: "pricing_conright" }, [
              _c("div", { staticClass: "pricing_title" }, [
                _c("h3", [_vm._v("StrategyBrain")])
              ]),
              _c("div", { staticClass: "pricing_monthly" }, [
                _c(
                  "div",
                  {
                    staticClass: "pricing_monthlyopan",
                    class: { active: _vm.active },
                    staticStyle: { direction: "ltr" }
                  },
                  [
                    _c(
                      "span",
                      {
                        class: _vm.lang,
                        on: {
                          click: function($event) {
                            return _vm.tabHandler()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Monthly")))]
                    ),
                    _c(
                      "span",
                      {
                        class: _vm.lang,
                        on: {
                          click: function($event) {
                            return _vm.tabHandler()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Annually")))]
                    )
                  ]
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "pric_switchingall" }, [
            _c(
              "div",
              {
                staticClass: "pric_switchingitem",
                class: { show: _vm.active },
                attrs: { title: "Monthly" }
              },
              [
                _c("div", { staticClass: "pric_switchinglist" }, [
                  _c(
                    "div",
                    {
                      staticClass: "price_swlistitem price_swlistitemlie1",
                      style: { marginRight: _vm.lang == "AR" ? 0 : "16px" }
                    },
                    [
                      _c("div", { staticClass: "priceitemtop" }, [
                        _c("h2", { staticClass: "priceitemtop_title" }, [
                          _vm._v(_vm._s(_vm.$t("Manual")))
                        ]),
                        _c("div", { staticClass: "priceitemtop_desc" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("ReducecostsandImprove")) + " "
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "priceitemtop_price" },
                          [
                            _c("b", [_vm._v("$")]),
                            _c("big", [_vm._v("5000")]),
                            _c("span", [
                              _vm._v(
                                "/" +
                                  _vm._s(
                                    _vm.lang == "US"
                                      ? "monthly"
                                      : _vm.$t("Monthly")
                                  )
                              )
                            ])
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "priceitembottom" }, [
                        _c("div", { staticClass: "priceitem_content" }, [
                          _c("p", [
                            _vm._v(_vm._s(_vm.$t("40hoursofworkperweek")))
                          ]),
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("ClientsAndCandidatesNeed")) +
                                " "
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("EachClientAndCandidate")) +
                                " "
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("DelayedresponsesMakeTimely")) +
                                " "
                            )
                          ])
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "price_swlistitem price_swlistitemlie2" },
                    [
                      _c("div", { staticClass: "priceitemtop" }, [
                        _c("h2", { staticClass: "priceitemtop_title" }, [
                          _vm._v(_vm._s(_vm.$t("FreeTrial")))
                        ]),
                        _c("div", { staticClass: "priceitemtop_desc" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("ExploreourPlatform")) + " "
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "priceitemtop_price" },
                          [
                            _c("b", [_vm._v("$")]),
                            _c("big", [_vm._v("0")]),
                            _c("span", [
                              _vm._v("/" + _vm._s(_vm.$t("FirstWeek")))
                            ])
                          ],
                          1
                        ),
                        _c("div", { staticClass: "priceitemtop_botselect" }, [
                          _c("h3", [
                            _vm._v(_vm._s(_vm.$t("Botselection")) + "：")
                          ]),
                          _c("div", { staticClass: "priceitemtop_botflex" }, [
                            _c(
                              "div",
                              {
                                staticClass: "priceitemtop_botflexitem",
                                class: {
                                  active: _vm.switchActive,
                                  disableCick: _vm.disableCick
                                },
                                on: { click: _vm.switchHandler }
                              },
                              [
                                _c("span", { staticClass: "prbot_label" }, [
                                  _vm._v(_vm._s(_vm.$t("Sales")))
                                ]),
                                _c("span", { staticClass: "prbot_button" })
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "priceitemtop_botflexitem",
                                class: {
                                  active: _vm.switchActive,
                                  disableCick: _vm.disableCick
                                },
                                on: { click: _vm.switchHandler }
                              },
                              [
                                _c("span", { staticClass: "prbot_label" }, [
                                  _vm._v(_vm._s(_vm.$t("Recruiter")))
                                ]),
                                _c("span", { staticClass: "prbot_button" })
                              ]
                            )
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "priceitembottom" }, [
                        _c("div", { staticClass: "price_conswich" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "price_conswichitem price_conswichitemqh",
                              class: { show: !_vm.switchActive }
                            },
                            [
                              _c("div", { staticClass: "priceitem_content" }, [
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("CoreFeature")) + ":")
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("AllcorefeaturesofAISalesRep")
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Campaign")) + ":")
                                  ]),
                                  _vm._v(" 1 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("SearchResults")) + ":"
                                    )
                                  ]),
                                  _vm._v(" 30 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("ConnectionRequest")) + ":"
                                    )
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("Partiallysupported")) +
                                      " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Connection")) + ":")
                                  ]),
                                  _vm._v(" 27 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Conversation")) + ":")
                                  ]),
                                  _vm._v(" 50 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("DashBoard")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Notsupported")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("HRExpert")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ])
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "price_conswichitem price_conswichitemqh",
                              class: { show: _vm.switchActive }
                            },
                            [
                              _c("div", { staticClass: "priceitem_content" }, [
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("CoreFeature")) + ":")
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("AllcoreAIRecruiter")) +
                                      " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Campaign")) + ":")
                                  ]),
                                  _vm._v(" 1 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("SearchResults")) + ":"
                                    )
                                  ]),
                                  _vm._v(" 30 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("ConnectionRequest")) + ":"
                                    )
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("Partiallysupported")) +
                                      " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Connection")) + ":")
                                  ]),
                                  _vm._v(" 27 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Conversation")) + ":")
                                  ]),
                                  _vm._v(" 50 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("DashBoard")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Notsupported")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("HRExpert")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ])
                              ])
                            ]
                          )
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "price_swlistitem price_swlistitemlie3" },
                    [
                      _c("div", { staticClass: "priceitemtop" }, [
                        _c("h2", { staticClass: "priceitemtop_title" }, [
                          _vm._v(_vm._s(_vm.$t("LitePlan")))
                        ]),
                        _c("div", {
                          staticClass: "priceitemtop_desc",
                          domProps: {
                            innerHTML: _vm._s(_vm.$t("ChooseEitherAISale100"))
                          }
                        }),
                        _c("div", { staticClass: "priceitemtop_monthpr" }, [
                          _vm._v(
                            " US$" +
                              _vm._s(
                                _vm.planList[0] && _vm.planList[0][1]
                                  ? _vm.planList[0] && _vm.planList[0][1].price
                                  : "0"
                              ) +
                              "/" +
                              _vm._s(_vm.$t("month")) +
                              " "
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "priceitemtop_price" },
                          [
                            _c("b", [_vm._v("≈$")]),
                            _c("big", [
                              _vm._v(
                                _vm._s(
                                  _vm.planList[0] && _vm.planList[0][1]
                                    ? (
                                        _vm.planList[0] &&
                                        _vm.planList[0][1].price / 30
                                      ).toFixed(2)
                                    : "0"
                                )
                              )
                            ]),
                            _c("span", [_vm._v("/" + _vm._s(_vm.$t("day")))])
                          ],
                          1
                        ),
                        _c("div", { staticClass: "priceitemtop_dally" }, [
                          _vm._v(" " + _vm._s(_vm.$t("paidMonthly")) + " ")
                        ]),
                        _c("div", { staticClass: "priceitemtop_getbtn" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  return _vm.pay(_vm.planList[0][1])
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Purchase1")))]
                          )
                        ]),
                        _c("div", { staticClass: "priceitemtop_botselect" }, [
                          _c("h3", [
                            _vm._v(_vm._s(_vm.$t("Botselection")) + "：")
                          ]),
                          _c("div", { staticClass: "priceitemtop_botflex" }, [
                            _c(
                              "div",
                              {
                                staticClass: "priceitemtop_botflexitem",
                                class: {
                                  active: _vm.switchActive,
                                  disableCick: _vm.disableCick
                                },
                                on: { click: _vm.switchHandler }
                              },
                              [
                                _c("span", { staticClass: "prbot_label" }, [
                                  _vm._v(_vm._s(_vm.$t("Sales")))
                                ]),
                                _c("span", { staticClass: "prbot_button" })
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "priceitemtop_botflexitem",
                                class: {
                                  active: _vm.switchActive,
                                  disableCick: _vm.disableCick
                                },
                                on: { click: _vm.switchHandler }
                              },
                              [
                                _c("span", { staticClass: "prbot_label" }, [
                                  _vm._v(_vm._s(_vm.$t("Recruiter")))
                                ]),
                                _c("span", { staticClass: "prbot_button" })
                              ]
                            )
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "priceitembottom" }, [
                        _c("div", { staticClass: "price_conswich" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "price_conswichitem price_conswichitemqh",
                              class: { show: !_vm.switchActive }
                            },
                            [
                              _c("div", { staticClass: "priceitem_content" }, [
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("CoreFeature")) + ":")
                                  ]),
                                  _vm._v(" All core features of sales rep ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Campaign")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Unlimited")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("SearchResults")) + ":"
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Unlimited")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("ConnectionRequest")) + ":"
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Connection")) + ":")
                                  ]),
                                  _vm._v(" 40 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Conversation")) + ":")
                                  ]),
                                  _vm._v(" 100 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("DashBoard")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("HRExpert")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ])
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "price_conswichitem price_conswichitemqh",
                              class: { show: _vm.switchActive }
                            },
                            [
                              _c("div", { staticClass: "priceitem_content" }, [
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("CoreFeature")) + ":")
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("AllcoreAIRecruiter")) +
                                      " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Campaign")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Unlimited")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("SearchResults")) + ":"
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Unlimited")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("ConnectionRequest")) + ":"
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Connection")) + ":")
                                  ]),
                                  _vm._v(" 40 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Conversation")) + ":")
                                  ]),
                                  _vm._v(" 100 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("DashBoard")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("HRExpert")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ])
                              ])
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "priceitemtop_getbtnflex" }, [
                          _c("div", { staticClass: "priceitemtop_getbtn" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.pay(_vm.planList[0][1])
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Purchase1")))]
                            )
                          ])
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "price_swlistitem price_swlistitemlie4" },
                    [
                      _c("div", { staticClass: "pricehot" }, [
                        _c("span", [_vm._v("Best Seller")])
                      ]),
                      _c("div", { staticClass: "priceitemtop" }, [
                        _c("h2", { staticClass: "priceitemtop_title" }, [
                          _vm._v(_vm._s(_vm.$t("ProPlan")))
                        ]),
                        _c("div", {
                          staticClass: "priceitemtop_desc",
                          domProps: {
                            innerHTML: _vm._s(_vm.$t("ChooseEitherAISale500"))
                          }
                        }),
                        _c("div", { staticClass: "priceitemtop_monthpr" }, [
                          _vm._v(
                            " US$" +
                              _vm._s(
                                _vm.planList[0] && _vm.planList[0][2]
                                  ? _vm.planList[0] && _vm.planList[0][2].price
                                  : "0"
                              ) +
                              "/" +
                              _vm._s(_vm.$t("month")) +
                              " "
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "priceitemtop_price" },
                          [
                            _c("b", [_vm._v("≈$")]),
                            _c("big", [
                              _vm._v(
                                _vm._s(
                                  _vm.planList[0] && _vm.planList[0][2]
                                    ? (
                                        _vm.planList[0] &&
                                        _vm.planList[0][2].price / 30
                                      ).toFixed(2)
                                    : "0"
                                )
                              )
                            ]),
                            _c("span", [_vm._v("/" + _vm._s(_vm.$t("day")))])
                          ],
                          1
                        ),
                        _c("div", { staticClass: "priceitemtop_dally" }, [
                          _vm._v(" " + _vm._s(_vm.$t("paidMonthly")) + " ")
                        ]),
                        _c("div", { staticClass: "priceitemtop_getbtn" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  return _vm.pay(_vm.planList[0][2])
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Purchase1")))]
                          )
                        ]),
                        _c("div", { staticClass: "priceitemtop_botselect" }, [
                          _c("h3", [
                            _vm._v(_vm._s(_vm.$t("Botselection")) + "：")
                          ]),
                          _c("div", { staticClass: "priceitemtop_botflex" }, [
                            _c(
                              "div",
                              {
                                staticClass: "priceitemtop_botflexitem",
                                class: {
                                  active: _vm.switchActive,
                                  disableCick: _vm.disableCick
                                },
                                on: { click: _vm.switchHandler }
                              },
                              [
                                _c("span", { staticClass: "prbot_label" }, [
                                  _vm._v(_vm._s(_vm.$t("Sales")))
                                ]),
                                _c("span", { staticClass: "prbot_button" })
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "priceitemtop_botflexitem",
                                class: {
                                  active: _vm.switchActive,
                                  disableCick: _vm.disableCick
                                },
                                on: { click: _vm.switchHandler }
                              },
                              [
                                _c("span", { staticClass: "prbot_label" }, [
                                  _vm._v(_vm._s(_vm.$t("Recruiter")))
                                ]),
                                _c("span", { staticClass: "prbot_button" })
                              ]
                            )
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "priceitembottom" }, [
                        _c("div", { staticClass: "price_conswich" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "price_conswichitem price_conswichitemqh",
                              class: { show: !_vm.switchActive }
                            },
                            [
                              _c("div", { staticClass: "priceitem_content" }, [
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("CoreFeature")) + ":")
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("AllcorefeaturesofAISalesRep")
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Campaign")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Unlimited")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("SearchResults")) + ":"
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Unlimited")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("ConnectionRequest")) + ":"
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Connection")) + ":")
                                  ]),
                                  _vm._v(" 120 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Conversation")) + ":")
                                  ]),
                                  _vm._v(" 500 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("DashBoard")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("HRExpert")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ])
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "price_conswichitem price_conswichitemqh",
                              class: { show: _vm.switchActive }
                            },
                            [
                              _c("div", { staticClass: "priceitem_content" }, [
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("CoreFeature")) + ":")
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("AllcoreAIRecruiter")) +
                                      " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Campaign")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Unlimited")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("SearchResults")) + ":"
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Unlimited")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("ConnectionRequest")) + ":"
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Connection")) + ":")
                                  ]),
                                  _vm._v(" 120 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Conversation")) + ":")
                                  ]),
                                  _vm._v(" 500 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("DashBoard")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("HRExpert")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ])
                              ])
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "priceitemtop_getbtnflex" }, [
                          _c("div", { staticClass: "priceitemtop_getbtn" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.pay(_vm.planList[0][2])
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Purchase1")))]
                            )
                          ])
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "price_swlistitem price_swlistitemlie5",
                      style: { marginRight: _vm.lang == "AR" ? "16px" : "0px" }
                    },
                    [
                      _c("div", { staticClass: "priceitemtop" }, [
                        _c("h2", { staticClass: "priceitemtop_title" }, [
                          _vm._v(_vm._s(_vm.$t("PrimePlan")))
                        ]),
                        _c("div", {
                          staticClass: "priceitemtop_desc",
                          domProps: {
                            innerHTML: _vm._s(_vm.$t("GainunlimitedAccess"))
                          }
                        }),
                        _c("div", { staticClass: "priceitemtop_monthpr" }, [
                          _vm._v(
                            " US$" +
                              _vm._s(
                                _vm.planList[0] && _vm.planList[0][3]
                                  ? _vm.planList[0] && _vm.planList[0][3].price
                                  : "0"
                              ) +
                              "/" +
                              _vm._s(_vm.$t("month")) +
                              " "
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "priceitemtop_price" },
                          [
                            _c("b", [_vm._v("≈$")]),
                            _c("big", [
                              _vm._v(
                                _vm._s(
                                  _vm.planList[0] && _vm.planList[0][3]
                                    ? (
                                        _vm.planList[0] &&
                                        _vm.planList[0][3].price / 30
                                      ).toFixed(2)
                                    : "0"
                                )
                              )
                            ]),
                            _c("span", [_vm._v("/" + _vm._s(_vm.$t("day")))])
                          ],
                          1
                        ),
                        _c("div", { staticClass: "priceitemtop_dally" }, [
                          _vm._v(" " + _vm._s(_vm.$t("paidMonthly")) + " ")
                        ]),
                        _c("div", { staticClass: "priceitemtop_getbtn" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  return _vm.pay(_vm.planList[0][3], 1)
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Purchase1")))]
                          )
                        ]),
                        _c("div", { staticClass: "priceitemtop_botselect" }, [
                          _c("h3", [
                            _vm._v(_vm._s(_vm.$t("Botselection")) + "：")
                          ]),
                          _c("div", { staticClass: "priceitemtop_botflex" }, [
                            _c(
                              "div",
                              {
                                staticClass: "priceitemtop_botflexitem noclick"
                              },
                              [
                                _c("span", { staticClass: "prbot_label" }, [
                                  _vm._v(_vm._s(_vm.$t("Sales")))
                                ]),
                                _c("span", { staticClass: "prbot_button" })
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "priceitemtop_botflexitem noclick"
                              },
                              [
                                _c("span", { staticClass: "prbot_label" }, [
                                  _vm._v(_vm._s(_vm.$t("Recruiter")))
                                ]),
                                _c("span", { staticClass: "prbot_button" })
                              ]
                            )
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "priceitembottom" }, [
                        _c("div", { staticClass: "price_conswich" }, [
                          _c(
                            "div",
                            { staticClass: "price_conswichitem show" },
                            [
                              _c("div", { staticClass: "priceitem_content" }, [
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("CoreFeature")) + ": "
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#0e756a" } },
                                      [_vm._v(_vm._s(_vm.$t("Allfeatures")))]
                                    )
                                  ])
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Campaign")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Unlimited")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("SearchResults")) + ":"
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Unlimited")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("ConnectionRequest")) + ":"
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Connection")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Unlimited")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Conversation")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Unlimited")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("DashBoard")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("HRExpert")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ])
                              ])
                            ]
                          ),
                          _c("div", { staticClass: "price_conswichitem" }, [
                            _c("div", { staticClass: "priceitem_content" }, [
                              _c("p", [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("CoreFeature")) + ":")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("Allfeatures")) + " "
                                )
                              ]),
                              _c("p", [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("Campaign")) + ":")
                                ]),
                                _vm._v(" " + _vm._s(_vm.$t("Unlimited")) + " ")
                              ]),
                              _c("p", [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("SearchResults")) + ":")
                                ]),
                                _vm._v(" " + _vm._s(_vm.$t("Unlimited")) + " ")
                              ]),
                              _c("p", [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.$t("ConnectionRequest")) + ":"
                                  )
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                )
                              ]),
                              _c("p", [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("Connection")) + ":")
                                ]),
                                _vm._v(" " + _vm._s(_vm.$t("Unlimited")) + " ")
                              ]),
                              _c("p", [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("Conversation")) + ":")
                                ]),
                                _vm._v(" " + _vm._s(_vm.$t("Unlimited")) + " ")
                              ]),
                              _c("p", [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("DashBoard")) + ":")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                )
                              ]),
                              _c("p", [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("HRExpert")) + ":")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                )
                              ])
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "priceitemtop_getbtnflex" }, [
                          _c("div", { staticClass: "priceitemtop_getbtn" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.pay(_vm.planList[0][3], 1)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Purchase1")))]
                            )
                          ])
                        ])
                      ])
                    ]
                  )
                ])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "pric_switchingitem",
                class: { show: !_vm.active },
                attrs: { title: "Annually" }
              },
              [
                _c("div", { staticClass: "pric_switchinglist" }, [
                  _c(
                    "div",
                    {
                      staticClass: "price_swlistitem price_swlistitemlie1",
                      style: { marginRight: _vm.lang == "AR" ? 0 : "16px" }
                    },
                    [
                      _c("div", { staticClass: "priceitemtop" }, [
                        _c("h2", { staticClass: "priceitemtop_title" }, [
                          _vm._v(_vm._s(_vm.$t("Manual")))
                        ]),
                        _c("div", { staticClass: "priceitemtop_desc" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("ReducecostsandImprove")) + " "
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "priceitemtop_price" },
                          [
                            _c("b", [_vm._v("$")]),
                            _c("big", [_vm._v("5000")]),
                            _c("span", [
                              _vm._v(
                                "/" +
                                  _vm._s(
                                    _vm.lang == "US"
                                      ? "monthly"
                                      : _vm.$t("Monthly")
                                  )
                              )
                            ])
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "priceitembottom" }, [
                        _c("div", { staticClass: "priceitem_content" }, [
                          _c("p", [
                            _vm._v(_vm._s(_vm.$t("40hoursofworkperweek")))
                          ]),
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("ClientsAndCandidatesNeed")) +
                                " "
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("EachClientAndCandidate")) +
                                " "
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("DelayedresponsesMakeTimely")) +
                                " "
                            )
                          ])
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "price_swlistitem price_swlistitemlie2" },
                    [
                      _c("div", { staticClass: "priceitemtop" }, [
                        _c("h2", { staticClass: "priceitemtop_title" }, [
                          _vm._v(_vm._s(_vm.$t("FreeTrial")))
                        ]),
                        _c("div", { staticClass: "priceitemtop_desc" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("ExploreourPlatform")) + " "
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "priceitemtop_price" },
                          [
                            _c("b", [_vm._v("$")]),
                            _c("big", [_vm._v("0")]),
                            _c("span", [
                              _vm._v("/" + _vm._s(_vm.$t("FirstWeek")))
                            ])
                          ],
                          1
                        ),
                        _c("div", { staticClass: "priceitemtop_botselect" }, [
                          _c("h3", [
                            _vm._v(_vm._s(_vm.$t("Botselection")) + "：")
                          ]),
                          _c("div", { staticClass: "priceitemtop_botflex" }, [
                            _c(
                              "div",
                              {
                                staticClass: "priceitemtop_botflexitem",
                                class: {
                                  active: _vm.switchActive,
                                  disableCick: _vm.disableCick
                                },
                                on: { click: _vm.switchHandler }
                              },
                              [
                                _c("span", { staticClass: "prbot_label" }, [
                                  _vm._v(_vm._s(_vm.$t("Sales")))
                                ]),
                                _c("span", { staticClass: "prbot_button" })
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "priceitemtop_botflexitem",
                                class: {
                                  active: _vm.switchActive,
                                  disableCick: _vm.disableCick
                                },
                                on: { click: _vm.switchHandler }
                              },
                              [
                                _c("span", { staticClass: "prbot_label" }, [
                                  _vm._v(_vm._s(_vm.$t("Recruiter")))
                                ]),
                                _c("span", { staticClass: "prbot_button" })
                              ]
                            )
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "priceitembottom" }, [
                        _c("div", { staticClass: "price_conswich" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "price_conswichitem price_conswichitemqh",
                              class: { show: !_vm.switchActive }
                            },
                            [
                              _c("div", { staticClass: "priceitem_content" }, [
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("CoreFeature")) + ":")
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("AllcorefeaturesofAISalesRep")
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Campaign")) + ":")
                                  ]),
                                  _vm._v(" 1 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("SearchResults")) + ":"
                                    )
                                  ]),
                                  _vm._v(" 30 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("ConnectionRequest")) + ":"
                                    )
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("Partiallysupported")) +
                                      " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Connection")) + ":")
                                  ]),
                                  _vm._v(" 27 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Conversation")) + ":")
                                  ]),
                                  _vm._v(" 50 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("DashBoard")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Notsupported")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("HRExpert")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ])
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "price_conswichitem price_conswichitemqh",
                              class: { show: _vm.switchActive }
                            },
                            [
                              _c("div", { staticClass: "priceitem_content" }, [
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("CoreFeature")) + ":")
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("AllcoreAIRecruiter")) +
                                      " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Campaign")) + ":")
                                  ]),
                                  _vm._v(" 1 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("SearchResults")) + ":"
                                    )
                                  ]),
                                  _vm._v(" 30 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("ConnectionRequest")) + ":"
                                    )
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("Partiallysupported")) +
                                      " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Connection")) + ":")
                                  ]),
                                  _vm._v(" 27 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Conversation")) + ":")
                                  ]),
                                  _vm._v(" 50 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("DashBoard")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Notsupported")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("HRExpert")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ])
                              ])
                            ]
                          )
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "price_swlistitem price_swlistitemlie3" },
                    [
                      _c("div", { staticClass: "priceitemtop" }, [
                        _c("h2", { staticClass: "priceitemtop_title" }, [
                          _vm._v(_vm._s(_vm.$t("LitePlan")))
                        ]),
                        _c("div", {
                          staticClass: "priceitemtop_desc",
                          domProps: { innerHTML: _vm._s(_vm.$t("litePlanDes")) }
                        }),
                        _c("div", { staticClass: "priceitemtop_monthpr" }, [
                          _c("span", [
                            _vm._v(
                              "US$ " +
                                _vm._s(
                                  _vm.planList[1] && _vm.planList[1][1]
                                    ? _vm.planList[1][1].original_price
                                    : 0
                                ) +
                                "/" +
                                _vm._s(_vm.$t("year"))
                            )
                          ]),
                          _c("span", [
                            _vm._v(
                              "$ " +
                                _vm._s(
                                  _vm.planList[1] && _vm.planList[1][1]
                                    ? _vm.planList[1][1].price
                                    : 0
                                ) +
                                "/" +
                                _vm._s(_vm.$t("year"))
                            )
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "priceitemtop_price" },
                          [
                            _c("b", [_vm._v("≈$")]),
                            _c("big", [
                              _vm._v(
                                _vm._s(
                                  _vm.planList[1] && _vm.planList[1][1]
                                    ? (_vm.planList[1][1].price / 365).toFixed(
                                        2
                                      )
                                    : 0
                                )
                              )
                            ]),
                            _c("span", [_vm._v("/" + _vm._s(_vm.$t("day")))])
                          ],
                          1
                        ),
                        _c("div", { staticClass: "priceitemtop_dally" }, [
                          _vm._v(" " + _vm._s(_vm.$t("paidAnnually")) + " ")
                        ]),
                        _c("div", { staticClass: "priceitemtop_getbtn" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  return _vm.pay(_vm.planList[1][1])
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Purchase1")))]
                          )
                        ]),
                        _c("div", { staticClass: "priceitemtop_botselect" }, [
                          _c("h3", [
                            _vm._v(_vm._s(_vm.$t("Botselection")) + "：")
                          ]),
                          _c("div", { staticClass: "priceitemtop_botflex" }, [
                            _c(
                              "div",
                              {
                                staticClass: "priceitemtop_botflexitem",
                                class: {
                                  active: _vm.switchActive,
                                  disableCick: _vm.disableCick
                                },
                                on: { click: _vm.switchHandler }
                              },
                              [
                                _c("span", { staticClass: "prbot_label" }, [
                                  _vm._v(_vm._s(_vm.$t("Sales")))
                                ]),
                                _c("span", { staticClass: "prbot_button" })
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "priceitemtop_botflexitem",
                                class: {
                                  active: _vm.switchActive,
                                  disableCick: _vm.disableCick
                                },
                                on: { click: _vm.switchHandler }
                              },
                              [
                                _c("span", { staticClass: "prbot_label" }, [
                                  _vm._v(_vm._s(_vm.$t("Recruiter")))
                                ]),
                                _c("span", { staticClass: "prbot_button" })
                              ]
                            )
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "priceitembottom" }, [
                        _c("div", { staticClass: "price_conswich" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "price_conswichitem price_conswichitemqh",
                              class: { show: !_vm.switchActive }
                            },
                            [
                              _c("div", { staticClass: "priceitem_content" }, [
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("CoreFeature")) + ":")
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("AllcorefeaturesofAISalesRep")
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Campaign")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Unlimited")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("SearchResults")) + ":"
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Unlimited")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("ConnectionRequest")) + ":"
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Connection")) + ":")
                                  ]),
                                  _vm._v(" 40 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Conversation")) + ":")
                                  ]),
                                  _vm._v(" 100 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("DashBoard")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("HRExpert")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ])
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "price_conswichitem price_conswichitemqh",
                              class: { show: _vm.switchActive }
                            },
                            [
                              _c("div", { staticClass: "priceitem_content" }, [
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("CoreFeature")) + ":")
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("AllcoreAIRecruiter")) +
                                      " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Campaign")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Unlimited")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("SearchResults")) + ":"
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Unlimited")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("ConnectionRequest")) + ":"
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Connection")) + ":")
                                  ]),
                                  _vm._v(" 40 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Conversation")) + ":")
                                  ]),
                                  _vm._v(" 100 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("DashBoard")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("HRExpert")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ])
                              ])
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "priceitemtop_getbtnflex" }, [
                          _c("div", { staticClass: "priceitemtop_getbtn" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.pay(_vm.planList[1][1])
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Purchase1")))]
                            )
                          ])
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "price_swlistitem price_swlistitemlie4" },
                    [
                      _c("div", { staticClass: "pricehot" }, [
                        _c("span", [_vm._v("Best Seller")])
                      ]),
                      _c("div", { staticClass: "priceitemtop" }, [
                        _c("h2", { staticClass: "priceitemtop_title" }, [
                          _vm._v(_vm._s(_vm.$t("ProPlan")))
                        ]),
                        _c("div", {
                          staticClass: "priceitemtop_desc",
                          domProps: { innerHTML: _vm._s(_vm.$t("proPlanDes")) }
                        }),
                        _c("div", { staticClass: "priceitemtop_monthpr" }, [
                          _c("span", [
                            _vm._v(
                              "US$ " +
                                _vm._s(
                                  _vm.planList[1] && _vm.planList[1][2]
                                    ? _vm.planList[1][2].original_price
                                    : 0
                                ) +
                                "/" +
                                _vm._s(_vm.$t("year"))
                            )
                          ]),
                          _c("span", [
                            _vm._v(
                              "$ " +
                                _vm._s(
                                  _vm.planList[1] && _vm.planList[1][2]
                                    ? _vm.planList[1][2].price
                                    : 0
                                ) +
                                "/" +
                                _vm._s(_vm.$t("year"))
                            )
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "priceitemtop_price" },
                          [
                            _c("b", [_vm._v("≈$")]),
                            _c("big", [
                              _vm._v(
                                _vm._s(
                                  _vm.planList[1] && _vm.planList[1][2]
                                    ? (_vm.planList[1][2].price / 365).toFixed(
                                        2
                                      )
                                    : 0
                                )
                              )
                            ]),
                            _c("span", [_vm._v("/" + _vm._s(_vm.$t("day")))])
                          ],
                          1
                        ),
                        _c("div", { staticClass: "priceitemtop_dally" }, [
                          _vm._v(" " + _vm._s(_vm.$t("paidAnnually")) + " ")
                        ]),
                        _c("div", { staticClass: "priceitemtop_getbtn" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  return _vm.pay(_vm.planList[1][2])
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Purchase1")))]
                          )
                        ]),
                        _c("div", { staticClass: "priceitemtop_botselect" }, [
                          _c("h3", [
                            _vm._v(_vm._s(_vm.$t("Botselection")) + "：")
                          ]),
                          _c("div", { staticClass: "priceitemtop_botflex" }, [
                            _c(
                              "div",
                              {
                                staticClass: "priceitemtop_botflexitem",
                                class: {
                                  active: _vm.switchActive,
                                  disableCick: _vm.disableCick
                                },
                                on: { click: _vm.switchHandler }
                              },
                              [
                                _c("span", { staticClass: "prbot_label" }, [
                                  _vm._v(_vm._s(_vm.$t("Sales")))
                                ]),
                                _c("span", { staticClass: "prbot_button" })
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "priceitemtop_botflexitem",
                                class: {
                                  active: _vm.switchActive,
                                  disableCick: _vm.disableCick
                                },
                                on: { click: _vm.switchHandler }
                              },
                              [
                                _c("span", { staticClass: "prbot_label" }, [
                                  _vm._v(_vm._s(_vm.$t("Recruiter")))
                                ]),
                                _c("span", { staticClass: "prbot_button" })
                              ]
                            )
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "priceitembottom" }, [
                        _c("div", { staticClass: "price_conswich" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "price_conswichitem price_conswichitemqh",
                              class: { show: !_vm.switchActive }
                            },
                            [
                              _c("div", { staticClass: "priceitem_content" }, [
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("CoreFeature")) + ":")
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("AllcorefeaturesofAISalesRep")
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Campaign")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Unlimited")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("SearchResults")) + ":"
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Unlimited")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("ConnectionRequest")) + ":"
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Connection")) + ":")
                                  ]),
                                  _vm._v(" 120 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Conversation")) + ":")
                                  ]),
                                  _vm._v(" 500 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("DashBoard")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("HRExpert")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ])
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "price_conswichitem price_conswichitemqh",
                              class: { show: _vm.switchActive }
                            },
                            [
                              _c("div", { staticClass: "priceitem_content" }, [
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("CoreFeature")) + ":")
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("AllcoreAIRecruiter")) +
                                      " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Campaign")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Unlimited")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("SearchResults")) + ":"
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Unlimited")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("ConnectionRequest")) + ":"
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Connection")) + ":")
                                  ]),
                                  _vm._v(" 120 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Conversation")) + ":")
                                  ]),
                                  _vm._v(" 500 ")
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("DashBoard")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("HRExpert")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ])
                              ])
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "priceitemtop_getbtnflex" }, [
                          _c("div", { staticClass: "priceitemtop_getbtn" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.pay(_vm.planList[1][2])
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Purchase1")))]
                            )
                          ])
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "price_swlistitem price_swlistitemlie5",
                      style: { marginRight: _vm.lang == "AR" ? "16px" : "0px" }
                    },
                    [
                      _c("div", { staticClass: "priceitemtop" }, [
                        _c("h2", { staticClass: "priceitemtop_title" }, [
                          _vm._v(_vm._s(_vm.$t("PrimePlan")))
                        ]),
                        _c("div", {
                          staticClass: "priceitemtop_desc",
                          domProps: {
                            innerHTML: _vm._s(_vm.$t("primePlanDes"))
                          }
                        }),
                        _c("div", { staticClass: "priceitemtop_monthpr" }, [
                          _c("span", [
                            _vm._v(
                              "US$ " +
                                _vm._s(
                                  _vm.planList[1] && _vm.planList[1][3]
                                    ? _vm.planList[1][1].original_price
                                    : 0
                                ) +
                                "/" +
                                _vm._s(_vm.$t("year"))
                            )
                          ]),
                          _c("span", [
                            _vm._v(
                              "$ " +
                                _vm._s(
                                  _vm.planList[1] && _vm.planList[1][3]
                                    ? _vm.planList[1][3].price
                                    : 0
                                ) +
                                "/" +
                                _vm._s(_vm.$t("year"))
                            )
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "priceitemtop_price" },
                          [
                            _c("b", [_vm._v("≈$")]),
                            _c("big", [
                              _vm._v(
                                _vm._s(
                                  _vm.planList[1] && _vm.planList[1][3]
                                    ? (_vm.planList[1][3].price / 365).toFixed(
                                        2
                                      )
                                    : 0
                                )
                              )
                            ]),
                            _c("span", [_vm._v("/" + _vm._s(_vm.$t("day")))])
                          ],
                          1
                        ),
                        _c("div", { staticClass: "priceitemtop_dally" }, [
                          _vm._v(" " + _vm._s(_vm.$t("paidAnnually")) + " ")
                        ]),
                        _c("div", { staticClass: "priceitemtop_getbtn" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  return _vm.pay(_vm.planList[1][3], 1)
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Purchase1")))]
                          )
                        ]),
                        _c("div", { staticClass: "priceitemtop_botselect" }, [
                          _c("h3", [
                            _vm._v(_vm._s(_vm.$t("Botselection")) + "：")
                          ]),
                          _c("div", { staticClass: "priceitemtop_botflex" }, [
                            _c(
                              "div",
                              {
                                staticClass: "priceitemtop_botflexitem noclick"
                              },
                              [
                                _c("span", { staticClass: "prbot_label" }, [
                                  _vm._v(_vm._s(_vm.$t("Sales")))
                                ]),
                                _c("span", { staticClass: "prbot_button" })
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "priceitemtop_botflexitem noclick"
                              },
                              [
                                _c("span", { staticClass: "prbot_label" }, [
                                  _vm._v(_vm._s(_vm.$t("Recruiter")))
                                ]),
                                _c("span", { staticClass: "prbot_button" })
                              ]
                            )
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "priceitembottom" }, [
                        _c("div", { staticClass: "price_conswich" }, [
                          _c(
                            "div",
                            { staticClass: "price_conswichitem show" },
                            [
                              _c("div", { staticClass: "priceitem_content" }, [
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("CoreFeature")) + ": "
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#0e756a" } },
                                      [_vm._v(_vm._s(_vm.$t("Allfeatures")))]
                                    )
                                  ])
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Campaign")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Unlimited")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("SearchResults")) + ":"
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Unlimited")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("ConnectionRequest")) + ":"
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Connection")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Unlimited")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("Conversation")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Unlimited")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("DashBoard")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ]),
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$t("HRExpert")) + ":")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                  )
                                ])
                              ])
                            ]
                          ),
                          _c("div", { staticClass: "price_conswichitem" }, [
                            _c("div", { staticClass: "priceitem_content" }, [
                              _c("p", [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("CoreFeature")) + ":")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("Allfeatures")) + " "
                                )
                              ]),
                              _c("p", [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("Campaign")) + ":")
                                ]),
                                _vm._v(" " + _vm._s(_vm.$t("Unlimited")) + " ")
                              ]),
                              _c("p", [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("SearchResults")) + ":")
                                ]),
                                _vm._v(" " + _vm._s(_vm.$t("Unlimited")) + " ")
                              ]),
                              _c("p", [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.$t("ConnectionRequest")) + ":"
                                  )
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                )
                              ]),
                              _c("p", [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("Connection")) + ":")
                                ]),
                                _vm._v(" " + _vm._s(_vm.$t("Unlimited")) + " ")
                              ]),
                              _c("p", [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("Conversation")) + ":")
                                ]),
                                _vm._v(" " + _vm._s(_vm.$t("Unlimited")) + " ")
                              ]),
                              _c("p", [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("DashBoard")) + ":")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                )
                              ]),
                              _c("p", [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("HRExpert")) + ":")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("Fullysupported")) + " "
                                )
                              ])
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "priceitemtop_getbtnflex" }, [
                          _c("div", { staticClass: "priceitemtop_getbtn" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.pay(_vm.planList[1][3], 1)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Purchase1")))]
                            )
                          ])
                        ])
                      ])
                    ]
                  )
                ])
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }